<template>
  <section>
    <div v-if="getData.length && loading" class="MainPageContent">
      <div>
        <HerbariumShowOptions
          :minItems="minItems"
          :maxItems="maxItems"
          :showGrid="showGrid"
          :showList="showList"
          :changeShowItemsMore="changeShowItemsMore"
          :changeShowItemsLess="changeShowItemsLess"
        />
      </div>

      <div v-if="switchType" class="MainPageContentCards">
        <Herbarium :herbarium="getData" :paginatedItems="this.paginatedItems" />
      </div>
      <div v-else class="MainPageContentList">
        <HerbariumList
          :herbarium="getData"
          :paginatedItems="this.paginatedItems"
        />
      </div>

      <Pagination
        v-if="getData.length > perPage"
        :totalRows="this.totalRows"
        :perPage="this.perPage"
        :currentPage="currentPage"
        :onPageChanged="onPageChanged"
      />
    </div>
    <div v-if="!getData.length && loading" class="text-center">
      <h2 class="MainPageContentNoData">{{ $t('main_page_no_herbarium') }}</h2>
    </div>
    <div v-if="!loading" class="text-center mt-5">
      <b-spinner variant="success" label="Text Centered"></b-spinner>
    </div>
  </section>
</template>

<script>
import Herbarium from '@/views/Herbarium.vue'
import Pagination from '@/components/Pagination.vue'
import HerbariumList from '@/views/HerbariumLists.vue'
import HerbariumShowOptions from '@/components/HerbariumShowOptions.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HerbariumMainPage',
  components: {
    HerbariumShowOptions,
    Pagination,
    Herbarium,
    HerbariumList
  },
  data() {
    return {
      switchType: true,
      items: [],
      paginatedItems: [],
      perPage: 3,
      totalRows: 0,
      currentPage: 1,
      minItems: 3,
      maxItems: 6,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['getData']),
    pageCount() {
      let l = this.totalRows,
        s = this.perPage
      return Math.floor(l / s)
    }
  },
  async mounted() {
    await this.fetchTags(this.$route.query.name)

    this.items = this.getData
    this.paginatedItems = this.getData
    this.totalRows = this.getData.length
    this.paginate(this.perPage, 0)
    this.loading = true
  },
  methods: {
    ...mapActions(['fetchData', 'fetchTags']),
    showGrid() {
      this.switchType = true
    },
    showList() {
      this.switchType = false
    },
    paginate(page_size, page_number) {
      this.paginatedItems = this.items.slice(
        page_number * page_size,
        (page_number + 1) * page_size
      )
    },
    onPageChanged(page) {
      this.paginate(this.perPage, page - 1)
    },
    changeShowItemsLess() {
      this.perPage = this.minItems
      this.paginate(this.perPage, 0)
    },
    changeShowItemsMore() {
      this.perPage = this.maxItems
      this.paginate(this.perPage, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.MainPageContentNoData {
  font-size: 30px;
  color: rgb(23, 77, 47);
  text-align: center;
  margin-top: 60px;
}
.MainPageContent {
  height: 800px;
  margin: 30px 0 20px;
  display: flex;
  flex-direction: column;
}
.MainPageContentCards {
  min-height: 650px;
  padding: 40px 0 60px 0;
}
.MainPageContentList {
  min-height: 650px;
  padding: 40px 20px 60px 0;
  overflow-y: auto;
}
</style>
