<template>
  <div class="paginationNav">
    <div class="col-12">
      <b-pagination
        v-model="page"
        class="my-0"
        :total-rows="totalRows"
        :per-page="perPage"
        @change="onPageChanged"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totalRows: Number,
    perPage: Number,
    currentPage: Number,
    onPageChanged: Function
  },
  data() {
    return {
      page: this.currentPage
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  justify-content: center;
}
</style>
